import { Box, CloseIcon, IconButton, Typography } from '@secfi/components';
import React, { FC } from 'react';

interface Props {
  onClose: () => void;
  icon: JSX.Element | string | null;
  message: JSX.Element | string | null;
}

export const Toast: FC<Props> = ({ icon, onClose, message }) => {
  return (
    <Box
      sx={{
        width: ['100%', 'auto'],
        maxWidth: ['100%', '384px'],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        p: 1,
      }}
    >
      {icon}
      <Typography component={'div'} data-testid="toast-message">
        {message}
      </Typography>

      <IconButton
        sx={{
          width: 24,
          height: 24,
        }}
        onClick={onClose}
        trackingId={'close-toast'}
      >
        <CloseIcon
          sx={{
            width: '1rem',
            height: '1rem',
          }}
          fontSize="small"
        />
      </IconButton>
    </Box>
  );
};
