import { useNavigation } from '@remix-run/react';
import NProgress from 'nprogress';
import React from 'react';

/**
 * This component listens to the navigation state and starts or stops the progress bar accordingly.
 * We introduce a delay to avoid showing the progress bar for very fast navigations.
 */
const THROTTLE_DELAY = 100; // delay in milliseconds

export const NavigationProgress: React.FC = () => {
  const navigation = useNavigation();
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    const headerWithMenu = document.querySelector('.header-with-menu');

    if (!headerWithMenu) {
      return;
    }

    if (navigation.state === 'idle') {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      NProgress.done();
    } else {
      timeoutRef.current = setTimeout(() => {
        NProgress.start();
      }, THROTTLE_DELAY);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [navigation.state]);

  return null;
};
