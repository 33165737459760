import { palette } from '@secfi/styles';
import React from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import { Toast } from 'src/components/Toast/Toast';

export const ToastRenderer = () => {
  return (
    <Toaster
      toastOptions={{
        position: 'top-right',
        duration: 5000,
        success: {
          iconTheme: {
            primary: palette.green[500],
            secondary: 'white',
          },
        },
        error: {
          iconTheme: {
            primary: palette.red[500],
            secondary: 'white',
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <Toast
              icon={icon}
              message={message}
              onClose={() => toast.dismiss(t.id)}
            />
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};
