import { Location } from 'history';
import { useEffect } from 'react';
import { AppPaths } from 'src/constants/routes';

const REDIRECT_PATH_KEY = 'redirect-after-login';

export const useSetRedirectAfterLogin = (location: Location) => {
  useEffect(() => {
    const redirectTo = location.pathname + location.search;
    if (
      redirectTo === AppPaths.Dashboard ||
      redirectTo === '/' ||
      location.search.includes('code=')
    ) {
      // the app will go to the home page anyway
      return;
    }
    setRedirectAfterLogin(redirectTo);
  }, [location]);
};

const setRedirectAfterLogin = (redirectTo: string) => {
  return sessionStorage.setItem(REDIRECT_PATH_KEY, redirectTo);
};

export const getRedirectAfterLogin = () => {
  return sessionStorage.getItem(REDIRECT_PATH_KEY);
};

export const getParsedRedirectAfterLogin = () => {
  const redirect = getRedirectAfterLogin();

  if (!redirect) {
    return null;
  }

  const parsedRedirectTo = new URL(redirect, window.location.origin);

  clearRedirectAfterLogin();

  return {
    pathname: parsedRedirectTo.pathname,
    search: parsedRedirectTo.search,
    hash: parsedRedirectTo.hash,
  } as const;
};

export const clearRedirectAfterLogin = () => {
  return sessionStorage.removeItem(REDIRECT_PATH_KEY);
};
